<template>
 <v-container class="">
    <v-row class="mx-0">
      <v-col class="text-center ">
          <BannerImage />
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
// @ is an alias to /src
import BannerImage from '@/components/BannerImage.vue'

export default {
  name: 'home',
  components: {
    BannerImage
  }
}
</script>
